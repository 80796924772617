<template>
<section>
  <h2 class="text-center text-3xl text-red-600 font-bold pt-16">Check our Socials for latest news, tips and offers</h2>
  <div class="flex items-center justify-center pt-8">
    <a href="https://www.facebook.com/dan.alley.58" target="_blank"><i class="pi pi-facebook" style="font-size: 4rem; color: blue;"></i></a>
    <a href="https://www.instagram.com/alleynutrition" target="_blank"><i class="pi pi-instagram" style="font-size: 4rem; color: gold; margin: 20px"></i></a>
  </div>
</section>


</template>

<script>
export default {

}
</script>

<style>

</style>