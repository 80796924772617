<template>
<div class="h-screen w-full">
  <div class="text-center py-6">
    <h1 class="text-2xl text-red-600 font-bold px-2 pb-4">Ready to get started or want to find out more...</h1>
    <h2 class="text-l text-gray-800">Get in touch for a no obligation chat about what I can do for you</h2>
  </div>
  <HomeContact />
</div>
  
</template>

<script>
import HomeContact from '../components/HomeContact.vue'
export default {
  components: { HomeContact }

}
</script>

<style>

</style>